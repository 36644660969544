import React, { useEffect, FC } from 'react'
import styles from './Steps.module.scss'
import Image from 'next/image'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Link from 'next/link'
import cn from "classnames";

type StepsProps = {
  title: any
  subtitle: any
  button: string
  buttonLink: string
  animation?: string
  icon1: string
  icon2: string
  icon3: string
  containerScaling?: string
  clubText: boolean
  indexStyles?: boolean
}

const Steps: FC<StepsProps> = ({
  title,
  subtitle,
  button,
  buttonLink,
  animation ,
  icon1,
  icon2,
  icon3,
  containerScaling,
  clubText,
  indexStyles
}) => {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])

  return (
    <section className={cn(styles.sectionMain, containerScaling)}>
      <div className={styles.sectionMainContainer}>
        <div className={styles.sectionMainHeader}>
          {subtitle}
          {title}
        </div>
        <div className={styles.sectionMainBody}>
          <div className={styles.steps}>
            <div className={styles.stepsContent}>
              <div
                data-aos={animation}
                data-aos-delay="300"
                data-aos-duration="1000"
                className={styles.stepsGroup}
              >
                <div>
                  <div className={styles.stepsNumber}>1.</div>
                  {clubText ?
                      <div className={styles.stepsHead}>
                        Become a <span>Basic Vitaliv Club</span> member. It&apos;s free!
                      </div> :
                      <div className={styles.stepsHead}>
                        Activate your <span>1-Month Free Trial</span> membership by
                        completing the first order
                      </div>
                  }
                  {clubText &&
                      <div className={styles.stepsText}>
                        Make your first order to start your free Basic Club membership.
                        To remain a Basic Club member, just keep buying from us.
                      </div>
                  }
                  <div className={styles.stepsFoot}>
                    <div className={styles.stepList}>
                      <div className={cn(styles.stepItem, {[styles.itemClub]:clubText})}>
                        <div className={cn(styles.stepListIcon, {[styles.listIconClub]:clubText})}>
                          {clubText ? null :
                              <Image
                                  src={icon1}
                                  alt="calendarIcon"
                                  width={22}
                                  height={22}
                                  objectFit="contain"
                              />
                          }
                        </div>
                        {clubText ?
                            <div className={styles.stepListText}>
                              Choose from a wide range of high-quality dietary
                              supplements to start achieving your health goals
                            </div> :
                            <div className={styles.stepListText}>
                              Pay only for the products you&apos;ve ordered
                            </div>
                        }
                      </div>
                      <div className={cn(styles.stepItem, {[styles.itemClub]:clubText})}>
                        <div className={cn(styles.stepListIcon, {[styles.listIconClub]:clubText})}>
                          {clubText ? null :
                              <Image
                                  src={icon2}
                                  alt="product"
                                  width={22}
                                  height={22}
                                  objectFit="contain"
                              />
                          }
                        </div>
                        {clubText ?
                            <div className={styles.stepListText}>
                              Use 1-click easy orders
                            </div> :
                            <div className={styles.stepListText}>
                              During the 1-Month FREE TRIAL, you can
                              make as many orders as you like
                            </div>
                        }

                      </div>
                      <div className={cn(styles.stepItem, {[styles.itemClub]:clubText})}>
                        <div className={cn(styles.stepListIcon, {[styles.listIconClub]:clubText})}>
                          {clubText ? null :
                              <Image
                                  src={icon3}
                                  alt="membership"
                                  width={22}
                                  height={22}
                                  objectFit="contain"
                              />
                          }
                        </div>
                        {clubText ?
                            <div className={styles.stepListText}>
                              Get cashback and save more on upcoming orders
                            </div> :
                            <div className={styles.stepListText}>
                              There are no hidden or automatic payments for
                              membership!
                            </div>
                        }
                      </div>
                      {clubText &&
                          <div className={cn(styles.stepItem, {[styles.itemClub]:clubText})}>
                            <div className={cn(styles.stepListIcon, {[styles.listIconClub]:clubText})}></div>
                            <div className={styles.stepListText}>
                              Track your supplement intake, see your progress and
                              stay motivated to keep supporting your health
                            </div>
                          </div>
                      }
                    </div>
                  </div>
                </div>

                <div className={styles.stepsPic}>
                  <Image
                    src="/images/aboutUs/2222.png"
                    alt="club membership, step 1"
                    layout="fill"
                    objectFit="contain"
                  />
                </div>
              </div>
              <div
                data-aos={animation}
                data-aos-delay="300"
                data-aos-duration="1000"
                className={styles.stepsGroup}
              >
                <div>
                  <div className={styles.stepsNumber}>2.</div>
                  {clubText ? <div className={styles.stepsHead}>
                        Switch to a <span>Premium Vitaliv Club</span> <span>membership</span> if you want more!
                      </div> :
                      <div className={styles.stepsHead}>
                        Move to your <span>Health Goals</span> with <br/>
                        1-year full membership
                      </div>
                  }
                  {clubText ?
                      <div className={styles.stepsText}>
                        Starting from your second order, you can become a Premium Vitaliv Club member
                        at any moment and shop for our products, <b>paying 30% less than
                        the regular Club price</b>.
                      </div> :
                      <div className={styles.stepsText}>
                        Your membership will be added to your cart with your first
                        order, after the trial period expires
                      </div>
                  }
                  {indexStyles ?
                      <div>
                        <div className={styles.blockBannerIndex}>
                          <p className={styles.bannerTitleIndex}>$49</p>
                          <div className={styles.wrapperBanner}>
                            <p className={styles.bannerSubtitle}>1-year VITALIV CLUB membership</p>
                            <p className={styles.bannerSubText}>That&apos;s about $4 per month</p>
                          </div>
                        </div>
                      </div>
                      :

                  <div className={styles.stepsFoot}>
                    <div className={styles.wrapper}>
                      <p
                          // data-aos="fade-zoom-in"
                          // data-aos-easing="ease-in-back"
                          // data-aos-delay="300"
                          // data-aos-offset="0"
                          // data-aos-duration="1000"
                          className={styles.hint}
                      >
                        That&apos;s about $4 per month
                        <span
                            // data-aos="fade-zoom-in"
                            // data-aos-easing="ease-in-back"
                            // data-aos-delay="300"
                            // data-aos-offset="0"
                            // data-aos-duration="2500"
                            className={styles.hintArrow}
                        ></span>
                      </p>
                      <div className={styles.blockBanner}>
                        <p className={styles.bannerTitle}>$49</p>
                        <p className={styles.bannerSubtitle}>1-year Premium VITALIV CLUB membership</p>
                      </div>
                    </div>
                  </div>
                  }
                </div>

                <div className={styles.stepsPic}>
                  <Image
                    src="/images/aboutUs/step22.png"
                    alt="club membership, step 2"
                    layout="fill"
                    objectFit="contain"
                  />
                </div>
              </div>
              <div
                data-aos={animation}
                data-aos-delay="300"
                data-aos-duration="1000"
                className={styles.stepsGroup}
              >
                <div>
                  <div className={styles.stepsNumber}>3.</div>
                  {clubText ?
                      <div className={styles.stepsHead}>
                        Vitaliv Club membership <br/> is <span>your right choice</span>, always!
                      </div> :
                      <div className={styles.stepsHead}>
                        <span>Stay with Us</span> on Your Way to Healthy Longevity
                      </div>
                  }
                  {clubText ?
                      <div className={styles.stepsText}>
                        There are no hidden or automatic payments for products or memberships!
                        You pay only for products you&apos;ve ordered. <br/>
                        To become a Premium Club member, add the Premium membership fee to your order.
                      </div> :
                      <div className={styles.stepsText}>
                        To remain a Vitaliv Club member, complete the order with the
                        annual membership fee and just keep buying your supplements
                      </div>
                  }
                  <div className={styles.stepsFoot}>
                    <Link href={buttonLink}>
                      <a className={styles.stepsButton} href="https://shop.vitaliv.us/collections/all">
                        {button}
                      </a>
                    </Link>
                  </div>
                </div>

                <div className={styles.stepsPic}>
                  <Image
                    src="/images/aboutUs/step33.png"
                    alt="club membership, step 3"
                    layout="fill"
                    objectFit="contain"
                  />
                </div>
              </div>
            </div>
            {/*<div className={styles.stepsSide}>*/}
            {/*    <div className={styles.stepsSidePic}>*/}
            {/*        <img src="/images/aboutUs/step1.svg" alt=""/>*/}
            {/*    </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Steps
